import React, { useState } from "react";
import CommonDiv1 from "./pricing/components/CommonDiv1";
import Images from "../utils/Images";
import ImageTextSection from "../utils/ImageTextSection";
import FlexCardIcon from "../utils/FlexCardIcon";
import FlexCard from "../utils/FlexCard";
import Alert from "../utils/Alert";

export default function Home() {

  const [isDownloaded, setIsDownloaded] = useState(false);

  const text = [
    `Kahipay payment gateway is an API based solution
    used by businesses to accept payments online
    through different payment modes like Credit Cards,
    Debit Cards, UPI, Wallets, Netbanking, etc.`,
  ];

  const text2 = [
    `Reliability : Count on us to provide a secure and dependable payment gateway to process transactions without any hiccups.`,
    `Ease of Use : Our user-friendly interface and seamless integration make it simple for businesses to get up and running in no time.`,
    `Customized Solutions : We understand that every business is unique. Therefore, we offer tailored solutions to meet your specific payment requirements.`,
    `Data Security : Your data security is our top priority. Rest assured that your sensitive information is protected at all times.`,
  ];

  const arrayData = [
    {
      cardIcon: Images.icon1,
      cardTitle: `QR Code`,
      cardDescription: `Accept payments using UPI QR via mobile app.`,
    },
    {
      cardIcon: Images.icon2,
      cardTitle: `Payment Gateway`,
      cardDescription: `Accept payments from your customers with simple API integration.`,
    },
    {
      cardIcon: Images.icon3,
      cardTitle: `Technical Support`,
      cardDescription: `24x7 technical support or any other payment related queries.`,
    },
  ];
  const arrayData2 = [
    // `Plug-and-play APIs`,
    `Quick Onboarding`,
    // `PCI DSS compliance`,
    `24*7 technical support`,
  ];
  return (
    <>
      {/* <HeroContainer imageUrl={IMAGES.smsbgdiv1} ht="1200px" bgSize="cover"> */}
      {/* <HeroContainer> */}
      <CommonDiv1
        title={`Advanced technological
        solutions to streamline your
        financial operations.`}
        description={`Constantly staying ahead to offer you the finest
        proposition. Offers comprehensive solutions to
        support the prosperous growth of your local and
        global business endeavors.`}
        fgimage={Images.home}
        btns={["Contact Us"]}
        secondaryBtns={[
          {
            label: "Download App",
            onClick: () => {
              try {
                let fileurl = "https://kahipay.com/apk/Kahipay-6July.apk"
                let a = document.createElement('a');
                a.href = fileurl;
                a.download = fileurl.split("/").at(-1);
                a.click();
                setIsDownloaded(true)
              } catch (err) {
                console.log(err)
              }
            }
          }
        ]}
      />

      {isDownloaded && <Alert type="success" message="Thanks for downloading our app!" />}
      {/* <MainContainer style={{ marginTop: "-90px" }}>
        <Newsletter buttonText="Get App Link" placeholder="Enter mobile number" label="Download Our App" 
        labelStyle={{ fontSize: "1.3rem", fontWeight: "500", background: "linear-gradient(to right,#1da1f2 0,#2b0101 100%)",
          backgroundClip: "text",
          "-webkit-text-fill-color": "transparent"
        }} 
        mainStyle={{ margin: "auto" }} />
      </MainContainer> */}
      {/* </HeroContainer> */}
      <FlexCardIcon
        heading={`Easily accept online payments on
          your website or app
        `}
        para={`Kahipay payment gateway offers a secure and dependable solution for accepting
        online payments in your business.`}
        cardData={arrayData}
      />
      {/* <HeroContainer imageUrl={IMAGES.bg1}> */}
      <ImageTextSection
        heading="Kahipay online payment
        gateway, ensures smooth and
        effortless transactions for
        your customers."
        text={text}
        imageSrc={Images.home2}
        btn={["Talk to Expert"]}
      />

      <ImageTextSection
        heading="Why choose Kahipay
        payment gateway solution?"
        text={text2}
        imageSrc={Images.home3}
        drn={"row-reverse"}
      />
      <FlexCard heading={`What's new at Kahipay?`} cardData={arrayData2} />
      {/* </HeroContainer> */}
    </>
  );
}
